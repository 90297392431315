<template>
  <div>
    <div class="e-breadcrumb">服务项目设置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">服务项目
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">套餐
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==2}" @click="switchTable(2)">工位填充设置
              </div>
            </div>
          </div>
          <TabSserviceItem  v-if="tabIndex==0"/>
          <TabPackage v-if="tabIndex==1"/>
          <TabGw v-if="tabIndex==2"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TabSserviceItem from '../service-item/siEntry'
  import TabPackage from '../service-item/pEntry'
  import TabGw from '../service-item/gwEntry'

  export default {
    data() {
      return {

        delSiVisible: false,

        tabIndex: 0,

        delPaVisible: false,

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

      }
    },
    async created() {
     if (this.$route.query.index!=null){
           this.tabIndex=this.$route.query.index
     }
    },
    methods: {

      switchTable(tabIndex) {
          this.tabIndex = tabIndex
      },


    },
    components: {
      TabSserviceItem,
      TabPackage,
      TabGw,
    }
  }

</script>

<style scoped>

</style>
