<template>
  <div>
    <div style="margin-bottom: 35px">
      <div class="handle-box">
        <div>
          <span class="v-form-label">套餐名称:</span>
          <el-input placeholder="套餐名称" size="small" style="width:265px" v-model="packageName" clearable
                    class="handle-input mr10"></el-input>
          <span class="v-form-label">套餐类型:</span>
          <el-select size="small" clearable style="width: 265px" v-model="packageType" placeholder="请选择">
            <el-option
              v-for="item in packageTypeList"
              :key="item.dictValue"
              :label="item.name"
              :value="item.dictValue">
            </el-option>
          </el-select>
          <span class="v-form-label">套餐成本	:</span>
          <el-input placeholder="套餐成本	" size="small" style="width:265px" v-model="packageCost" clearable
                    class="handle-input mr10"></el-input>
          <span class="v-form-label">是否赠送项目/商品:</span>
          <el-select size="small" clearable style="width: 265px" v-model="isGive" placeholder="请选择">
            <el-option
              v-for="item in isGiveList"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="handle-box">
        <div style="margin-left: 80%" >
          <el-row type="flex" justify="end">
            <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
            </el-button>
            <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
            </el-button>
          </el-row>
        </div>
      </div>
    </div>

    <div class="e-table-wrapper">
      <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
        <thead>
        <tr>
          <th width="130" class="th-l">套餐名称</th>
          <th width="130" class="th-l">可购买日期</th>
          <th width="130" class="th-l">套餐类型</th>
          <th width="140" class="th-l">有效期</th>
          <th width="130" class="th-l">套餐成本</th>
          <th width="200" class="th-l">是否赠送项目/商品</th>
          <th width="160" class="th-l">创建时间</th>
          <th width="180" class="th-l">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
          <td class="td-l">
            {{row.packageName}}
          </td>
          <td class="td-l">
            <span v-if="row.purchaseStartDate">开始日期:{{row.purchaseStartDate.substring(0,10)}}</span>
            <br/>
            <span v-if="row.purchaseEndDate">结束日期:{{row.purchaseEndDate.substring(0,10)}}</span>
          </td>
          <td class="td-l">
            {{packageTypeMap[row.packageType]}}
          </td>
          <td class="td-l">
                  <span v-if="row.tovType==0">
                    购买日期延后 {{row.tovMonth}} 个月
                  </span>
            <span v-if="row.tovType==1">
                  到期日期 {{row.tovDate.substring(0,10)}}
                  </span>
          </td>
          <td class="td-l">
            {{row.packageCost}}
          </td>
          <td class="td-l">
            <span v-if="row.isGive==0">是</span>
            <span v-if="row.isGive==1">否</span>
          </td>
          <td class="td-l"><span>{{row.createTime}}</span></td>
          <td class="td-l">
            <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :pager-count="7"
          layout="total, prev, pager, next,jumper"
          :total="pagination.count"
          :page-count="pagination.pages"
          :current-page="pagination.pageNumber"
        ></el-pagination>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
            </span>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: "siEntry",
    data() {
      return {
        packageName: '',
        packageType: '',
        packageCost: '',
        isGive: '',


        categoryList: [],
        packageTypeList: [],

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        isGiveList: [
          {
            value: 0,
            name: '是'
          },
          {
            value: 1,
            name: '否'
          }],

        delVisible: false,
        item: {},
        packageTypeMap: {},
      }
    },
    async created() {
      this.searchEvent();
      this.getPackageTypeList()
    },
    methods: {

      async getPackageTypeList() {
        let res = await this.$get('/admin/getDictDetailList/PACKAGE_TYPE/' + Math.random());
        this.packageTypeList = res
        res.forEach(item => {
          this.packageTypeMap[item.dictValue] = item.name
        })
      },


      searchEvent() {
        this.requestSearch()
      },


      async requestSearch(page) {
        let currentPage = page || 1;

        let data = {
          packageName: this.packageName,
          packageType: this.packageType,
          packageCost: this.packageCost,
          isGive: this.isGive,
          isDel: 0,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }

        let res = await this.$get("/admin/getBsPackageForPage", data)
        if (res.code == 200) {
          this.list = res.dataSet.list;
          this.pagination = {
            count: res.dataSet.count,
            pageNumber: res.dataSet.pageNumber,
            pageSize: res.dataSet.pageSize,
            pages: res.dataSet.pages
          }
        } else {
          this.$message({
            showClose: true,
            message: dataSet.message,
            type: 'error'
          });
        }
      },


      addEvent() {
        this.$router.push('/baseData/savePackage')
      },

      editEvent(index) {
        this.$router.push('/baseData/updatePackage?id='+this.list[index].id)
      },

      async removeEvent(index) {
        this.item = this.list[index]
        this.delVisible = true;
      },

      //删除
      async delMethod() {
        let data = {
          id: this.item.id
        };

        let res = await this.$post("/admin/delete_bsPackage", data)

        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      //服务项目分类相关
      closeAsic() {
        this.addSiCategory = false;
      },
      asicSuccess() {
        this.closeAsic();
        this.searchEvent();
      },
      closeEsic() {
        this.editSiCategory = false;
      },
      esicSuccess() {
        this.closeEsic();
        this.searchEvent();
      },
    },
    components: {}
  }
</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
    .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}

</style>
