<template>
  <div>
    <div style="margin-bottom: 35px">
      <div class="handle-box">
        <span class="v-form-label">编号:</span>
        <el-input placeholder="编号" size="small" style="width:265px" v-model="itemNo" clearable
                  class="handle-input mr10"></el-input>
        <span class="v-form-label">名称:</span>
        <el-input placeholder="名称" size="small" style="width:265px" v-model="itemName" clearable
                  class="handle-input mr10"></el-input>
        <span class="v-form-label">分类:</span>
        <el-select size="small" clearable style="width: 265px" v-model="categoryId" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id">
          </el-option>
        </el-select>
        <span class="v-form-label">类别:</span>
        <el-select size="small" clearable style="width: 265px" v-model="itemType" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
        <div style="float:left">
          <el-row type="flex" justify="end">
            <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
            </el-button>
            <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
            </el-button>
          </el-row>
        </div>
      </div>

    </div>

    <div class="e-table-wrapper">
      <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
        <thead>
        <tr>
          <th width="130" class="th-l">编号</th>
          <th width="130" class="th-l">名称</th>
          <th width="130" class="th-l">分类</th>
          <th width="130" class="th-l">类别</th>
          <th width="130" class="th-l">定价</th>
          <th width="300" class="th-l">会员价设定</th>
          <th width="160" class="th-l">创建时间</th>
          <th width="300" class="th-l">员工记业绩</th>
          <th width="130" class="th-l">排序</th>
          <th width="300" class="th-l">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
          <td class="td-l"><span>{{row.itemNo}}</span></td>
          <td class="td-l"><span>{{row.itemName}}</span></td>
          <td class="td-l"><span>{{row.categoryName}}</span></td>
          <td class="td-l">
            <span v-if="row.itemType==0">小项目</span>
            <span v-if="row.itemType==1">大项目</span>
          </td>
          <td class="td-l">
            <p v-if="row.retailPrice">{{'零售价: '+row.retailPrice}}</p>
            <p v-if="row.minimumPrice">{{'最低价: '+row.retailPrice}}</p>
            <p @click="toDingJia(index)" style="cursor:pointer;color: #1b80ff">设置</p>
          </td>
          <td class="td-l" style="width: 450px;">
            <div v-if="row.itemCardPrices.length>0" v-for="item in row.itemCardPrices">
              {{item.cardName+' '+item.price+' 元'}}
              <span @click="toUpdateIC(index,item)" style="cursor:pointer;color: #1b80ff">
                [修改]
            </span>
              <span @click="toDelIC(item)" style="cursor:pointer;color: #1b80ff">
                [删除]
            </span>
            </div>
            <p @click="toHYDJ(index)" style="cursor:pointer;color: #1b80ff">设置</p>
          </td>
          <td class="td-l"><span>{{row.createTime}}</span></td>
          <td class="td-l" style="width: 450px;">
            <div v-if="row.achievementType">
              <span v-if="row.acType==0">
                [实业绩]
              </span>
              <span v-if="row.achievementType==0">
                固定业绩: {{row.achievementValue+' 元'}}
              </span>
              <span v-if="row.achievementType==1">
                水单业绩比例: {{row.achievementValue+' %'}}
              </span>
            </div>
            <p @click="toYGYJ(index)" style="cursor:pointer;color: #1b80ff">设置</p>
          </td>
          <td class="td-l">
            <span>
          {{row.sort}}
            </span>
          </td>
          <td class="td-l">
            <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
            <!--<el-button type="text" icon="el-icon-edit" @click="departmentEvent(index)">设置项目门店-->
            <!--</el-button>-->
            <el-button type="text" icon="el-icon-edit" @click="removeEvent(index)">删除
            </el-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :pager-count="7"
          layout="total, prev, pager, next,jumper"
          :total="pagination.count"
          :page-count="pagination.pages"
          :current-page="pagination.pageNumber"
        ></el-pagination>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="delIcVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delIcVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delitemCard">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="设置门店" :visible.sync="departmentVisible" width="660px" center>
      <div style="width: 600px">
        <el-checkbox v-model="checkAll" @change="checkAllEvent">全选</el-checkbox>
        <div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>
        <template v-for="(sd,key) in cities">
          <div style="margin: 15px 0;"></div>
          <el-checkbox v-model="sd.checkAll" @change="handleCheckAllChange(key)">{{sd.name}}
          </el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="sd.checkedCities" @change="handleCheckedCitiesChange(key)">
            <el-checkbox v-for="item in sd.list" :label="item.id" :key="item.id">{{item.departmentName}}
            </el-checkbox>
          </el-checkbox-group>
          <div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="departmentVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="updateMethod">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="定价" :visible.sync="djVisible" width="35%">
      <div style="width: 600px">
        <el-form ref="form" label-width="100px" style="margin-top: 30px">
          <el-form-item size="small" label="项目名称:" required>
            <el-input v-model="item.itemName" placeholder="请输入项目名称" disabled maxlength="64" show-word-limit
                      style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="零售价:" required>
            <el-input v-model="retailPrice" placeholder="请输入零售价" maxlength="12" show-word-limit
                      style="width: 200px"></el-input>
            <span style="margin-left: 10px"> <el-checkbox v-model="checked">允许手动改价</el-checkbox></span>
          </el-form-item>
          <el-form-item size="small" label="最低价:" required>
            <el-input :disabled="!checked" v-model="minimumPrice" placeholder="请输入最低价" maxlength="12" show-word-limit
                      style="width: 200px"></el-input>
            <span style="margin-left: 10px">允许手动改价时可以改到的最低价</span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="djVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="updatePrice">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="会员定价" :visible.sync="hydjVisible" width="20%">
      <div style="width: 100%">
        <el-form ref="form" label-width="35%">
          <el-form-item size="small" label="项目名称:" required>
            <span>{{item.itemName}}</span>
          </el-form-item>
          <el-form-item size="small" label="会员卡类型:" required>
            <el-select v-model="cardId" placeholder="请选择" style="width: 60%">
              <el-option
                v-for="item in cardList"
                :key="item.id"
                :label="item.cardName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="small" label="会员价:" required>
            <el-input style="width: 60%" placeholder="价格" v-model="price">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="hydjVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="saveCardPrice">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="员工业绩" :visible.sync="ygyjVisible" width="30%">
      <div style="width: 100%">
        <el-form ref="form" label-width="20%">
          <el-form-item size="small" label="项目名称:" required>
            <span>{{item.itemName}}</span>
          </el-form-item>
          <div>
            <el-form-item size="small" label="记业绩:" required>
              <el-radio-group v-model="achievementType">
                <el-radio :label="'0'">固定业绩</el-radio>
                <el-radio :label="'1'">水单业绩比例</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item size="small" label="实业绩:" required>
              <el-input style="width: 40%" v-model="achievementValue">
                <template v-if="achievementType==0" slot="append">元</template>
                <template v-if="achievementType==1" slot="append">%</template>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="ygyjVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="updateItem">确 定</el-button>
            </span>
    </el-dialog>

  </div>

</template>

<script>
  export default {
    name: "siEntry",
    data() {
      return {
        checked: false,
        retailPrice: '',
        minimumPrice: '',

        itemName: '',
        itemNo: '',
        itemType: '',
        categoryId: '',
        categoryList: [],

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        typeList: [
          {
            name: '小项目',
            value: 0,
          },
          {
            name: '大项目',
            value: 1,
          }
        ],

        delVisible: false,
        item: {},

        departmentVisible: false,
        checkAll: false,
        allCheckCities: [], // 全部选中得数据
        cities: {},
        checkTotal: 0,

        djVisible: false,

        delIcVisible: false,
        hydjVisible: false,
        price: '',
        cardId: '',
        itemCardId: '',
        cardList: [],
        cardPriceList: [],

        ygyjVisible: false,
        achievementType: '0',
        achievementValue: '',
      }
    },
    async created() {
      this.searchEvent();
      this.getCategoryList();
      this.getCardList();
    },
    methods: {

      async getCardList() {
        let res = await this.$get("/admin/getCardList")
        if (res.code == 200) {
          this.cardList = res.cardList
        } else {
          this.$message.error(res.message)
        }
      },

      async getCategoryList() {
        let data = {
          categoryType: 0
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },

      async updateMethod() {
        let data = {
          itemId: this.item.id,
          departmentIdStr: JSON.stringify(this.allCheckCities)
        };
        let res = await this.$post("/admin/update_itemDepartment", data)
        if (res.code == 200) {
          this.searchEvent()
          this.departmentVisible = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },

      toDingJia(index) {
        this.item = this.list[index];
        this.retailPrice = this.item.retailPrice;
        this.minimumPrice = this.item.minimumPrice;
        if (this.item.allowManual === 1) {
          this.checked = true
        } else {
          this.checked = false
        }
        this.djVisible = true
      },

      toUpdateIC(index, item) {
        this.item = this.list[index];
        this.price = item.price
        this.cardId = item.cardId
        this.itemCardId = item.id
        this.hydjVisible = true
      },


      toYGYJ(index) {
        this.item = this.list[index];
        if (this.item.achievementType) {
          this.achievementType = this.item.achievementType
        } else {
          this.achievementType = '0'
        }
        this.achievementValue = this.item.achievementValue
        this.ygyjVisible = true
      },

      async updateItem() {
        let data = {
          id: this.item.id,
          achievementType: this.achievementType,
          achievementValue: this.achievementValue,
        };
        let res = await this.$post("/admin/updateItem", data)
        if (res.code == 200) {
          this.searchEvent()
          this.price = ''
          this.cardId = ''
          this.itemCardId = ''
          this.ygyjVisible = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },

      async updatePrice() {
        let allowManual = ''
        if (this.checked) {
          allowManual = 1
        } else {
          allowManual = 0
        }
        let data = {
          id: this.item.id,
          retailPrice: this.retailPrice,
          minimumPrice: this.minimumPrice,
          allowManual: allowManual,
        };
        let res = await this.$post("/admin/updatePrice", data)
        if (res.code == 200) {
          this.searchEvent()
          this.djVisible = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },

      toHYDJ(index) {
        this.item = this.list[index];
        this.hydjVisible = true
      },
      toDelIC(item) {
        this.itemCard = item
        this.delIcVisible = true
      },

      async saveCardPrice() {
        let data = {
          id: this.itemCardId,
          itemId: this.item.id,
          cardId: this.cardId,
          price: this.price,
        };
        let res = await this.$post("/admin/savePrice", data)
        if (res.code == 200) {
          this.searchEvent()
          this.price = ''
          this.cardId = ''
          this.itemCardId = ''
          this.hydjVisible = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },


      searchEvent() {
        this.requestSearch()
      },

      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          itemNo: this.itemNo,
          itemName: this.itemName,
          categoryId: this.categoryId,
          itemType: this.itemType,
          isDel: 0,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }

        let res = await this.$get("/admin/getBsServiceItemForPage", data)
        if (res.code == 200) {
          this.list = res.dataSet.list;
          console.log(this.list)
          this.pagination = {
            count: res.dataSet.count,
            pageNumber: res.dataSet.pageNumber,
            pageSize: res.dataSet.pageSize,
            pages: res.dataSet.pages
          }
        } else {
          this.$message({
            showClose: true,
            message: dataSet.message,
            type: 'error'
          });
        }
      },

      addEvent() {
        this.$router.push('/baseData/saveServiceItem')
      },

      editEvent(index) {
        this.$router.push('/baseData/updateServiceItem?id=' + this.list[index].id)
      },

      async departmentEvent(index) {
        this.item = this.list[index]
        this.departmentVisible = true;
        this.getDepartmens();
        this.getServiceItem(this.list[index].id)
      },
      async getServiceItem(id) {
        let res = await this.$get("/admin/getServiceItem", {id: id})
        if (res.code == 200) {
          this.allCheckCities = [...res.serviceItem.departmentIds]
        }
      },

      async removeEvent(index) {
        this.item = this.list[index]
        this.delVisible = true;
      },

      //删除
      async delMethod() {
        let data = {
          id: this.item.id
        };
        let res = await this.$post("/admin/delete_bsServiceItem", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message);
          this.delVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },

      //删除
      async delitemCard() {
        let data = {
          id: this.itemCard.id
        };
        let res = await this.$post("/admin/remove_itemCardPrice", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message);
          this.delIcVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      //服务项目分类相关
      closeAsic() {
        this.addSiCategory = false;
      },
      asicSuccess() {
        this.closeAsic();
        this.searchEvent();
      },
      closeEsic() {
        this.editSiCategory = false;
      },
      esicSuccess() {
        this.closeEsic();
        this.searchEvent();
      },

      async getDepartmens() {
        let checkTotal = 0;
        let cities = {};
        // let res = await getRegionList();
        let data = await this.$get("/admin/getDepartmens")
        let res = data
        for (let i = 0; i < res.length; i++) {
          let item = res[i];
          let sysDepartmentList = item['sysDepartmentList'];
          let ids = sysDepartmentList.map(sd => (sd.id));
          let checkedCities = this.allCheckCities.filter(item => {
            return ids.indexOf(item) > -1;
          });
          checkTotal += item['sysDepartmentList'].length;
          let checkAll = checkedCities.length === ids.length;
          cities[item.id] = {
            ids,
            checkAll,
            checkedCities,
            name: item.departmentName,
            list: item['sysDepartmentList']
          }
        }
        this.checkTotal = checkTotal;
        this.cities = cities;
      },
      // 监听 group 选择
      handleCheckedCitiesChange(key) {
        let item = this.cities[key];
        item.checkAll = item.ids.length === item.checkedCities.length;
        this.getCheckedLen()
      },
      // 监听全选
      handleCheckAllChange(key) {
        let item = this.cities[key];
        let checkAll = item.checkAll;
        item.checkedCities = checkAll ? item.ids : [];
        this.getCheckedLen()
      },
      checkAllEvent() {
        let cities = this.cities;
        if (this.checkAll) {
          for (let key in  cities) {
            let item = cities[key];
            item.checkedCities = item.ids;
            item.checkAll = true;
          }
        } else {
          for (let key in  cities) {
            let item = cities[key];
            item.checkAll = false;
            item.checkedCities = [];
          }
        }
        this.cities = cities;
        this.getAllCheckCityes();
      },
      getAllCheckCityes() {
        let allCheckCities = [];
        for (let key in  this.cities) {
          let item = this.cities[key];
          allCheckCities = [...allCheckCities, ...item.checkedCities]
        }
        this.allCheckCities = allCheckCities;
      },
      getCheckedLen() {
        let len = 0;
        for (let key in  this.cities) {
          let item = this.cities[key];
          len += item.checkedCities.length
        }
        if (this.checkTotal === len) {
          this.checkAll = true;
        } else {
          this.checkAll = false;
        }
        this.getAllCheckCityes();
      },
    },
    components: {}
  }
</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
  .off {
    background-color: #fff;
  }
  .on {
    background-color: #ececea;
  }
</style>
